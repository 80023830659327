import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const Footer = ({ t }) => {
  const data = useStaticQuery(graphql`
    query {
      fb: file(relativePath: { eq: "fb.svg" }) {
        publicURL
      }
      ig: file(relativePath: { eq: "ig.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <StyledFooter>
      <div>{t("footer.message")}</div>
      <div style={{ paddingTop: "10px" }}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/yassmarthk/"
        >
          <SocialIcon src={data.fb.publicURL} alt="facebook" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/yas.moment/"
        >
          <SocialIcon src={data.ig.publicURL} alt="instagram" />
        </a>
      </div>
    </StyledFooter>
  )
}

const SocialIcon = styled.img`
  height: 30px;
  width: 30px;
  margin: 0 10px;
`

const StyledFooter = styled.div`
  background-color: #2c2c2c;
  height: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  white-space: pre-line;
  padding: 0 16px;
  @media only screen and (max-width: 768px) {
    & {
      height: 200px;
    }
  }
`

export default Footer
