import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const ChangeLangButton = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language || "zh"
  const isEn = lang === "en"

  const toggleLang = () => {
    i18n.changeLanguage(isEn ? "zh" : "en")
  }

  return (
    <ChangeLangButtonWrapper onClick={toggleLang}>
      {isEn ? "繁體" : "Eng"}
    </ChangeLangButtonWrapper>
  )
}

const ChangeLangButtonWrapper = styled.div`
  border-radius: 8px;
  border: 2px solid #f27060;
  height: 30px;
  width: 70px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
  color: #f27060;
  cursor: pointer;
  user-select: none;
`

export default ChangeLangButton
