import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import CssBaseline from "@material-ui/core/CssBaseline"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import mobileBackground from "../images/bg-mobile.png"
import axios from "axios"

import { withTrans } from "../i18n/withTrans"
import Header from "./header"
import Footer from "./footer"
import { ThemeProvider, createMuiTheme } from "@material-ui/core"
import background from "../images/bg.png"
import "./layout.css"

const HOST = process.env.GATSBY_API_HOST
const END_DATE = process.env.GATSBY_END_DATE

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          caretColor: "#fff",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#fff",
        wordBreak: "keep-all",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#fff",
        "&$disabled": {
          color: "rgba(255, 255, 255, 0.5)",
        },
      },
    },
    MuiInput: {
      root: {
        color: "#FFF",
      },
      underline: {
        "&:before": {
          borderColor: "#fff !important",
        },
      },
    },
    MuiRadio: {
      root: {
        // color: "#fff",
        // marginRight: "25px",
      },
      colorSecondary: {
        color: "#FFF !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        flex: 1,
      },
    },
    MuiCheckbox: {
      root: {
        color: "#FFF",
      },
      colorSecondary: {
        color: "#FFF !important",
      },
    },
    MuiButton: {
      root: {
        color: "#56BAD1",
        fontWeight: "bold",
        background: "#fff !important",
        transitionDuration: "0.3s",
        transitionProperty: "transform",
        "&:hover": {
          transform: "scale(1.1)",
        },
        "@media only screen and (max-width: 600px)": {
          "&:hover": {
            transform: "scale(1)",
          },
        },
        "&$disabled": {
          opacity: "0.5 !important",
        },
      },
      textSizeSmall: {
        height: "30px",
        minWidth: "120px",
        borderRadius: "15px",
      },
      textSizeLarge: {
        height: "57px",
        fontSize: "20px",
        minWidth: "240px",
        borderRadius: "30px",
      },
    },
  },
})

const CampaginEnd = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language || "zh"

  return (
    <>
      <Content>
        <XXXXXX>
          <Topic lang={lang}>{t("end.topic")}</Topic>
          <Topic2>{t("end.topic2")}</Topic2>
        </XXXXXX>
      </Content>
    </>
  )
}

const useIsCampaginEnd = () => {
  const [isCampaginEnd, setIsCampaginEnd] = useState(
    new Date() > new Date(END_DATE)
  )

  async function getTime() {
    const resp = await axios({
      url: "/time",
      baseURL: HOST,
      method: "get",
    })

    setIsCampaginEnd(new Date(resp.data.time) > new Date(END_DATE))
  }

  useEffect(() => {
    getTime()
  }, [])

  return isCampaginEnd
}

const Layout = ({ children, t }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const isCampaignEnd = useIsCampaginEnd()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header siteTitle={data.site.siteMetadata.title} t={t} />
      <MainContent>
        <StyledMain style={{ flex: "1" }}>
          {isCampaignEnd ? <CampaginEnd /> : children}
        </StyledMain>
        <Footer t={t} />
      </MainContent>
    </ThemeProvider>
  )
}

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  @media only screen and (max-width: 600px) {
    min-height: auto;
  }
`

const StyledMain = styled.main`
  background-image: url(${background});
  background-size: 100% auto;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #f39d97;
  color: #fff;
  min-height: 700px;

  @media only screen and (max-width: 768px) {
    background: #f29d97;
    min-height: auto;
  }
`

const Topic = styled.div`
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    & {
      font-size: 50px;
    }
  }

  @media only screen and (max-width: 600px) {
    & {
      font-size: ${props => (props.lang === "en" ? "34px" : "40px")};
    }
  }
`

const Topic2 = styled.div`
  font-size: 18px;
  white-space: pre-line;
  @media only screen and (max-width: 600px) {
    & {
      font-size: 14px;
    }
  }
`

const Content = styled.div`
  margin: 0px auto;
  padding: 50px 30px 30px;
  max-width: 1360px;
  @media only screen and (max-width: 768px) {
    & {
      // padding: 50px 0px 16px;
      padding: 0px;
    }
  }
`

const XXXXXX = styled.div`
  @media only screen and (max-width: 768px) {
    & {
      background-image: url(${mobileBackground});
      background-size: contain;
      background-position-x: center;
      background-position-y: bottom;
      background-repeat: no-repeat;
      padding: 20px 16px 0px;
      height: calc(100vh - 110px);
    }
  }

  @media (max-width: 768px) and (orientation: landscape) {
    & {
      height: calc(100vw * 1.1);
    }
  }

  @media (max-width: 600px) and (orientation: portrait) {
    & {
      height: calc(100vh * 1.1);
      max-height: 600px;
    }
  }
`

export default withTrans(Layout)
