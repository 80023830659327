import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ChangeLangButton from "./changeLangButton"

const Header = ({ t, i18n }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "generali.png" }) {
        publicURL
      }
      yas: file(relativePath: { eq: "yas.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <StyledHeader>
      <HeaderContent>
        <HeaderLeft>
          <MobileLeft>
            <a href="/">
              <Logo src={data.yas.publicURL} alt={"yas logo"} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://bravo.generali.com.hk/"
            >
              <Logo src={data.logo.publicURL} alt={"generali logo"} />
            </a>
            {process.env.GATSBY_BRAND_LINK && process.env.GATSBY_BRAND_LOGO && (
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.GATSBY_BRAND_LINK}
              >
                <Logo
                  src={`/${process.env.GATSBY_BRAND_LOGO}`}
                  alt={"generali logo"}
                />
              </a>
            )}
          </MobileLeft>
          <MobileRight>
            <ChangeLangButton />
          </MobileRight>
        </HeaderLeft>
        <HeaderRight>
          <StyledLink target="_blank" href="https://yas.com.hk/blog/">
            {t("header.blog")}
          </StyledLink>
          <StyledLink target="_blank" href="https://yas.com.hk/">
            {t("header.about")}
          </StyledLink>
          <MobileHidden>
            <ChangeLangButton />
          </MobileHidden>
        </HeaderRight>
      </HeaderContent>
    </StyledHeader>
  )
}

const Logo = styled.img`
  height: 50px;
  @media only screen and (max-width: 768px) {
    & {
      height: 38px;
    }
  }
`

const StyledHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  width: 100vw;
  height: 100px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  @media only screen and (max-width: 768px) {
    & {
      height: auto;
    }
  }
`

const MobileRight = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    & {
      display: block;
    }
  }
`
const MobileLeft = styled.div`
  @media only screen and (max-width: 768px) {
    & {
      flex: 1;
    }
  }
`

const MobileHidden = styled.div`
  @media only screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`
const HeaderContent = styled.div`
  display: flex;
  max-width: 1360px;
  width: 1360px;
  margin: 0 auto;
  padding: 0 30px;
  @media only screen and (max-width: 768px) {
    & {
      flex-direction: column;
      padding: 0;
    }
  }
`

const HeaderLeft = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  padding-bottom: 10px;
  & img {
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    & {
      justify-content: center;
      padding: 10px 16px 10px;
    }
  }
`
const HeaderRight = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  @media only screen and (max-width: 768px) {
    & {
      background-color: #f27060;
      color: #fff;
      justify-content: center;
      padding: 10px 10px;
      display: flex;
      > a {
        flex: 1;
        text-align: center;
      }
    }
  }
`
const StyledLink = styled.a`
  margin: 0 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  &:hover {
    color: #f27060;
  }
  font-size: 18px;
  line-height: 30px;
  @media only screen and (max-width: 768px) {
    &:hover {
      color: inherit;
    }

    & {
      margin: 0px;
    }
  }
`

export default Header
