import i18next from "i18next"

i18next.init({
  fallbackLng: "zh",
  resources: {
    zh: {
      translations: require(`../../locale/${process.env.GATSBY_SITE_NAME}/zh/translations.json`),
    },
    en: {
      translations: require(`../../locale/${process.env.GATSBY_SITE_NAME}/en/translations.json`),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

i18next.languages = ["zh", "en"]

export default i18next
